.icon {
  &.brand {
    &-50 {
      filter: invert(96%) sepia(10%) saturate(139%) hue-rotate(120deg) brightness(98%) contrast(95%);
    }
    &-200 {
      filter: invert(84%) sepia(14%) saturate(554%) hue-rotate(117deg) brightness(101%) contrast(80%);
    }
    &-500 {
      filter: invert(61%) sepia(15%) saturate(1064%) hue-rotate(117deg) brightness(91%) contrast(93%);
    }
    &-700 {
      filter: invert(30%) sepia(98%) saturate(336%) hue-rotate(117deg) brightness(97%) contrast(91%);
    }
    &-900 {
      filter: invert(17%) sepia(38%) saturate(901%) hue-rotate(117deg) brightness(97%) contrast(91%);
    }
  }
  &.primary {
    &-50 {
      filter: invert(100%) sepia(3%) saturate(3421%) hue-rotate(331deg) brightness(130%) contrast(101%);
    }
    &-200 {
      filter: invert(100%) sepia(80%) saturate(333%) hue-rotate(174deg) brightness(101%) contrast(96%);
    }
    &-500 {
      filter: invert(92%) sepia(24%) saturate(53%) hue-rotate(162deg) brightness(98%) contrast(95%);
    }
    &-700 {
      filter: invert(20%) sepia(75%) saturate(488%) hue-rotate(170deg) brightness(91%) contrast(92%);
    }
    &-900 {
      filter: invert(12%) sepia(30%) saturate(3104%) hue-rotate(190deg) brightness(96%) contrast(98%);
    }
  }
  &.secondary {
    &-50 {
      filter: invert(92%) sepia(12%) saturate(145%) hue-rotate(244deg) brightness(100%) contrast(93%);
    }
    &-200 {
      filter: invert(74%) sepia(56%) saturate(169%) hue-rotate(240deg) brightness(93%) contrast(94%);
    }
    &-500 {
      filter: invert(13%) sepia(39%) saturate(4300%) hue-rotate(272deg) brightness(91%) contrast(87%);
    }
    &-700 {
      filter: invert(7%) sepia(45%) saturate(6370%) hue-rotate(280deg) brightness(93%) contrast(94%);
    }
    &-900 {
      filter: invert(4%) sepia(83%) saturate(6435%) hue-rotate(286deg) brightness(66%) contrast(98%);
    }
  }
  &.black {
    &-50 {
      filter: invert(84%) sepia(2%) saturate(571%) hue-rotate(145deg) brightness(117%) contrast(96%);
    }
    &-100 {
      filter: invert(98%) sepia(1%) saturate(2212%) hue-rotate(175deg) brightness(94%) contrast(103%);
    }
    &-200 {
      filter: invert(95%) sepia(6%) saturate(411%) hue-rotate(179deg) brightness(98%) contrast(84%);
    }
    &-300 {
      filter: invert(82%) sepia(17%) saturate(162%) hue-rotate(165deg) brightness(94%) contrast(86%);
    }
    &-400 {
      filter: invert(66%) sepia(9%) saturate(247%) hue-rotate(166deg) brightness(99%) contrast(91%);
    }
    &-500 {
      filter: invert(53%) sepia(6%) saturate(1085%) hue-rotate(166deg) brightness(93%) contrast(83%);
    }
    &-600 {
      filter: invert(38%) sepia(9%) saturate(1169%) hue-rotate(169deg) brightness(90%) contrast(85%);
    }
    &-700 {
      filter: invert(24%) sepia(33%) saturate(414%) hue-rotate(168deg) brightness(93%) contrast(85%);
    }
    &-800 {
      filter: invert(16%) sepia(25%) saturate(882%) hue-rotate(168deg) brightness(90%) contrast(85%);
    }
    &-900 {
      filter: invert(8%) sepia(90%) saturate(1339%) hue-rotate(185deg) brightness(92%) contrast(105%);
    }
  }
  &.brown {
    &-50 {
      filter: invert(88%) sepia(2%) saturate(1325%) hue-rotate(317deg) brightness(110%) contrast(101%);
    }
    &-100 {
      filter: invert(95%) sepia(36%) saturate(511%) hue-rotate(294deg) brightness(91%) contrast(123%);
    }
    &-200 {
      filter: invert(83%) sepia(8%) saturate(783%) hue-rotate(323deg) brightness(103%) contrast(103%);
    }
    &-300 {
      filter: invert(79%) sepia(26%) saturate(333%) hue-rotate(324deg) brightness(101%) contrast(99%);
    }
    &-400 {
      filter: invert(78%) sepia(11%) saturate(844%) hue-rotate(325deg) brightness(103%) contrast(99%);
    }
    &-500 {
      filter: invert(96%) sepia(8%) saturate(3974%) hue-rotate(299deg) brightness(101%) contrast(95%);
    }
    &-600 {
      filter: invert(64%) sepia(28%) saturate(775%) hue-rotate(324deg) brightness(95%) contrast(93%);
    }
    &-700 {
      filter: invert(20%) sepia(59%) saturate(1498%) hue-rotate(343deg) brightness(94%) contrast(98%);
    }
    &-800 {
      filter: invert(12%) sepia(23%) saturate(5549%) hue-rotate(352deg) brightness(95%) contrast(100%);
    }
    &-900 {
      filter: invert(8%) sepia(13%) saturate(5953%) hue-rotate(341deg) brightness(95%) contrast(105%);
    }
  }
  &.information {
    &-50 {
      filter: invert(96%) sepia(4%) saturate(442%) hue-rotate(181deg) brightness(99%) contrast(94%);
    }
    &-200 {
      filter: invert(82%) sepia(3%) saturate(4209%) hue-rotate(185deg) brightness(105%) contrast(93%);
    }
    &-500 {
      filter: invert(66%) sepia(35%) saturate(521%) hue-rotate(172deg) brightness(88%) contrast(93%);
    }
    &-700 {
      filter: invert(38%) sepia(50%) saturate(710%) hue-rotate(170deg) brightness(91%) contrast(87%);
    }
    &-900 {
      filter: invert(11%) sepia(24%) saturate(4285%) hue-rotate(192deg) brightness(102%) contrast(97%);
    }
  }
  &.success {
    &-50 {
      filter: invert(99%) sepia(19%) saturate(501%) hue-rotate(157deg) brightness(101%) contrast(87%);
    }
    &-200 {
      filter: invert(94%) sepia(11%) saturate(346%) hue-rotate(88deg) brightness(97%) contrast(94%);
    }
    &-500 {
      filter: invert(44%) sepia(28%) saturate(754%) hue-rotate(91deg) brightness(95%) contrast(92%);
    }
    &-700 {
      filter: invert(27%) sepia(10%) saturate(4179%) hue-rotate(94deg) brightness(98%) contrast(80%);
    }
    &-900 {
      filter: invert(14%) sepia(8%) saturate(5600%) hue-rotate(97deg) brightness(95%) contrast(95%);
    }
  }
  &.danger {
    &-50 {
      filter: invert(96%) sepia(4%) saturate(438%) hue-rotate(315deg) brightness(97%) contrast(94%);
    }
    &-200 {
      filter: invert(74%) sepia(16%) saturate(527%) hue-rotate(319deg) brightness(95%) contrast(85%);
    }
    &-500 {
      filter: invert(34%) sepia(62%) saturate(878%) hue-rotate(324deg) brightness(95%) contrast(89%);
    }
    &-700 {
      filter: invert(18%) sepia(95%) saturate(1672%) hue-rotate(346deg) brightness(93%) contrast(94%);
    }
    &-900 {
      filter: invert(5%) sepia(91%) saturate(6590%) hue-rotate(17deg) brightness(87%) contrast(95%);
    }
  }
  &.highlight {
    &-50 {
      filter: invert(89%) sepia(3%) saturate(634%) hue-rotate(315deg) brightness(109%) contrast(95%);
    }
    &-200 {
      filter: invert(80%) sepia(8%) saturate(1073%) hue-rotate(314deg) brightness(106%) contrast(95%);
    }
    &-500 {
      filter: invert(48%) sepia(54%) saturate(706%) hue-rotate(314deg) brightness(105%) contrast(95%);
    }
    &-700 {
      filter: invert(25%) sepia(81%) saturate(720%) hue-rotate(321deg) brightness(102%) contrast(85%)
    }
    &-900 {
      filter: invert(16%) sepia(28%) saturate(1953%) hue-rotate(322deg) brightness(88%) contrast(90%);
    }
  }
  &.warning {
    &-50 {
      filter: invert(97%) sepia(6%) saturate(1073%) hue-rotate(310deg) brightness(106%) contrast(95%);
    }
    &-200 {
      filter: invert(81%) sepia(51%) saturate(182%) hue-rotate(5deg) brightness(105%) contrast(91%);
    }
    &-500 {
      filter: invert(87%) sepia(28%) saturate(1273%) hue-rotate(335deg) brightness(106%) contrast(92%);
    }
    &-700 {
      filter: invert(58%) sepia(12%) saturate(3364%) hue-rotate(12deg) brightness(104%) contrast(91%);
    }
    &-900 {
      filter: invert(39%) sepia(8%) saturate(7468%) hue-rotate(21deg) brightness(96%) contrast(97%);
    }
  }

  &.white {
    filter: invert(94%) sepia(0%) saturate(0%) hue-rotate(167deg) brightness(105%) contrast(105%);
  }

  &.xxxs {
    width: 9px;
    height: 9px;
  }
  &.xxs {
    width: 12px;
    height: 12px;
  }
  &.xs {
    width: 16px;
    height: 16px;
  }
  &.xs {
    width: 16px;
    height: 16px;
  }
  &.sm {
    width: 24px;
    height: 24px;
  }
  &.md {
    width: 32px;
    height: 32px;
  }
  &.lg {
    width: 48px;
    height: 48px;
  }
}
