@import '@/styles/common/mixins';

.shipmentSelect {
  button {
    border: none;
    background-size: 30px;
    height: 3rem;
    padding-left: 60px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    outline: none !important;

    &:after {
      content: ' ';
      width: 15px;
      height: 15px;
      border-radius: 50px;
      position: absolute;
      top: 50%;
      left: 0%;
      transform: translate(-50%, -50%);
      border: 3px solid white;
      outline: #c8cbcd solid 3px !important;
    }

    &.active::after {
      background-color: #23a994;
    }

    strong {
      font-weight: 600;
    }
  }

  .valueSelect {
    gap: 7px;

    .customValueContainer {
      margin-top: 10px;
    }
  }
}

.tag {
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  background-color: theme('colors.highlight.DEFAULT');
  border-radius: 1px;
  padding: 5px 6px;
  padding-bottom: 3px;
}

.columns {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: space-between;

  @include md-screen-up($screen-lg) {
    flex-wrap: nowrap;
  }
}

.separator {
  border: 1px solid theme('colors.black.100');
  margin-top: 24px;
  margin-bottom: 24px;
}

.shippingMargin {
  margin-bottom: 8px !important;

  @include md-screen-up($screen-sm) {
    margin-bottom: 16px !important;
  }
}

.hideOnMobile {
  display: none;
  @include md-screen-up($screen-md) {
    display: block;
  }
}

.stepNav {
  @include md-screen-down($screen-md) {
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.08));
    position: fixed;
    padding-bottom: 10px;
    left: 0;
    right: 0;
    top: 0;
    background-color: theme('colors.white');
    padding-top: 15px;

    .title {
      font-weight: 600;
      border-bottom: none !important;
    }

    :global(.divider) {
      width: 33px !important;
    }

    :global(.steps) {
      width: 66px;
    }
  }
}

.mtOnMobile {
  @include md-screen-down($screen-md) {
    margin-top: 0px;
  }
}
