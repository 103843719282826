@import '../common/mixins';

.columns {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: space-between;

  @include md-screen-up($screen-lg) {
    flex-wrap: nowrap;
  }
}

.checkoutColumn {
  flex: 1;
}

.infoColumn {
  width: 100%;

  @include md-screen-up($screen-lg) {
    max-width: 330px;
  }
  @include md-screen-up($screen-xl) {
    max-width: 430px;
  }
}

.shippingMargin {
  margin-bottom: 8px !important;

  @include md-screen-up($screen-sm) {
    margin-bottom: 16px !important;
  }
}
