.button {
  border: none;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 1px solid black;
  }

  &:active:enabled * {
    height: 15px;
  }

  &:disabled {
    &.plus {
      background-color: theme('colors.primary.200');
    }

    &.minus {
      border-color: theme('colors.primary.200');
      color: theme('colors.black.600');
    }
  }

  * {
    height: 20px;
  }

  &.plus {
    background-color: theme('colors.primary.DEFAULT');
    color: theme('colors.white');
  }

  &.minus {
    color: theme('colors.black.900');
    background: none;
    border: 1px solid theme('colors.primary.DEFAULT');
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.number {
  width: 20px;
  text-align: center;
}
