@import '@/styles/common/mixins';

.container {
  position: relative;
  min-width: 300px;
  display: inline-block;

  @include md-screen-down($screen-md) {
    width: 100%;
  }
}

.header {
  border-radius: 5px;
  border: 1px solid theme('colors.primary.DEFAULT');
  width: 100%;
  background-color: white;
  text-align: left;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    justify-self: end;
    margin-left: auto;
    transition: transform 0.3s;
  }
}

.label {
  &.populated {
    position: absolute;
    font-size: 12px;
    top: 0;
  }
  color: theme('colors.black.600');
  background-color: white;
  transform: translateY(-50%);
  top: 50%;
  transition: top 0.3s, font-size 0.3s;
  margin: 0 -4px;
  padding: 0 4px;
  border-radius: 50%;

  position: absolute;
}

.list {
  list-style-type: none;
  margin-bottom: 0;

  li {
    cursor: pointer;
    padding: 12px 8px;
    margin: 4px 8px;
    border-radius: 5px;
    transition: background-color 0.3s;

    &:hover {
      background-color: theme('colors.black.100');
    }
  }
}

.listContainer {
  position: absolute;
  z-index: 999;
  top: calc(100% + 10px);
  width: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 4px 16px rgba(69, 91, 99, 0.4);
  padding: 0;
  overflow: scroll;
  padding: 4px 0;

  &.expanded {
    max-height: 400px !important;
  }
}
