.button {
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &.lg {
    width: 64px;
    height: 64px;
  }

  &.md {
    width: 48px;
    height: 48px;
  }

  &.sm {
    width: 32px;
    height: 32px;
  }

  &.primary,
  &.tertiary {
    img {
      filter: invert(1);
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    &.lg,
    &.md {
      width: 24px;
      height: 24px;
    }

    &.sm {
      width: 16px;
      height: 16px;
    }
  }
}
@supports selector(:focus-visible) {
  .button {
    &:focus-visible {
      outline: 4px solid rgba(81, 164, 146, 0.4);
    }
  }
}
