.card {
  padding: 24px 0;
  background: radial-gradient(56.78% 62.5% at 20.27% 35.09%, #ffffff 0%, #f2f2f2 100%);
  box-shadow: 0px 4px 8px rgba(214, 218, 226, 0.16);
  border-radius: 12px;
  text-align: center;
  font-weight: bold;

  h3 {
    color: theme('colors.secondary.DEFAULT');
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin: 0;
    margin-bottom: 16px;
    padding: 0;
  }

  div {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
  }
}

.container {
  h2,
  h2 span,
  p {
    margin: 0;
    font-size: 15px;
    line-height: 17px;
  }
  a {
    color: theme('colors.primary.DEFAULT');
    cursor: pointer;
  }

  h2 {
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 24px;
    color: theme('colors.black.600');
  }

  a {
    font-size: 16px;
    line-height: 17px;
  }
}
