@import '~react-calendar/dist/Calendar.css';

#klarna-payment {
  width: 100%;
  margin-top: 16px;
}

#klarna-placement::part(osm-container) {
  background-color: white;
  font-family: 'Brown', 'Roboto', sans-serif;
}

#klarna-checkout-container {
  width: 100%;
}

#snippet-container {
  min-width: 320px;
  width: 100%;
  margin-top: 16px;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}
.leaflet-popup-content {
  margin: 0px !important;
}
.leaflet-popup-content-wrapper {
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
}
.leaflet-container a.leaflet-popup-close-button {
  top: 4px !important;
  right: 4px !important;
}

.stars {
  height: 16px;
  line-height: 16px;
}

.subtitle .stars {
  display: inline-block;
}

.col-2 .stars {
  height: 24px;
  line-height: 24px;
  margin: 0px;
}

#write-review-title {
  margin: 40px 0 15px 0;
}

#write-review-container .stars {
  margin: 15px 0;
  height: 24px;
  line-height: 24px;
}

.write-review-input {
  border-width: 0 0 1px 0;
  line-height: 30px;
}

.review-list .media img {
  width: 65px;
  height: 65px;
  border-radius: 32.5px !important;
}

.text-link {
  color: theme('colors.information.500');
  text-decoration: underline;
}
