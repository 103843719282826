.card {
  padding: 40px 0;
  background: radial-gradient(56.78% 62.5% at 20.27% 35.09%, #ffffff 0%, #f2f2f2 100%);
  box-shadow: 0px 4px 8px rgba(214, 218, 226, 0.16);
  border-radius: 12px;
  text-align: center;

  input,
  textarea {
    background-color: transparent;
    font-weight: bold;
    padding: 0;
    position: relative;
    width: 100%;
    min-height: 40px;
    border: none;
    pointer-events: all;
    z-index: 10;
    word-wrap: break-word;
    color: #b7b9be;
    text-align: center;
    color: theme('colors.black.900');
    overflow: hidden;

    &.focus {
      color: theme('colors.black.900') !important;
    }

    &::placeholder {
      color: #b7b9be;
    }

    &.titleInput {
      font-size: 32px;
      line-height: 40px;
      color: theme('colors.secondary.DEFAULT');
    }
  }
}

.inputContainer {
  position: relative;
}

.inputMirror {
  font-weight: bold;
  position: absolute;
  white-space: pre;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: inline-block;
  z-index: 0;
  color: transparent;
  border-bottom: 1px solid;
  transition: border-bottom-color 0.5s;
  border-bottom-color: transparent;

  &.placeholder {
    border-color: #b7b9be;
  }

  &.titleInput {
    font-size: 32px;
    line-height: 40px;
  }

  &.focus {
    border-color: theme('colors.black.900') !important;
    border-width: 2px;
  }
}

.inputBorder {
  display: absolute;
}
