.floatingTag {
  position: absolute;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  top: -21px;
  left: 50%;
  padding: 5px 9px;
  padding-bottom: 3px;
  transform: translateX(-50%);
  border-radius: 4px 4px 0 0;
  margin: auto;
  text-align: center;
  background-color: theme('colors.warning.DEFAULT');
  white-space: nowrap;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
